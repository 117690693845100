import React, { useState, useEffect } from "react";
import Split from "react-split";
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark, vscodeLight } from "@uiw/codemirror-theme-vscode";
import { javascript } from "@codemirror/lang-javascript";
import { cpp } from "@codemirror/lang-cpp";
import { python } from "@codemirror/lang-python";
import { go } from "@codemirror/lang-go";
import { java } from "@codemirror/lang-java";
import { Button } from "react-bootstrap";
import { Divider } from "@mui/material";
import PreferenceNav from "./PreferenceNavbar/PreferenceNavbar";
import ProblemDiscription from "../ProblemDiscription/ProblemDiscription";
import NavigationBar from "../../NavigationBar/NavigationBar";
import "./Playground.css";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import ErrorToast from "components/Toaster/ErrorToast";
import api from "../../../api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TestCaseModal from '../../TestCase/TestCaseModal';

const Workspace = () => {
  const [language, setLanguage] = useState("python");
  const [code, setCode] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const selectedModuleId = useSelector((state) => state.module.selectedModuleId);
  const [theme, setTheme] = useState('light');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customInput, setCustomInput] = useState('');
  const [toastMessage, setToastMessage] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [selectedTestCase, setSelectedTestCase] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [testCases, setTestCases] = useState([]);

  const boilerPlates = {
    javascript: `// Write your JavaScript code here`,
    cpp: `// Write your C++ code here`,
    python: `# Write your Python code here`,
    java: `// Write your Java code here`,
    go: `// Write your Go code here`,
  };

  const languageExtensions = {
    javascript: javascript(),
    cpp: cpp(),
    python: python(),
    java: java(),
    go: go(),
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const response = await api.get(`course/${selectedModuleId}/p/`);
        const fetchedQuestions = response?.data || [];
        setQuestions(fetchedQuestions);
        
        if (fetchedQuestions.length > 0) {
          const firstQuestion = fetchedQuestions[0];
          const firstSubmission = firstQuestion.submission_data;
          if (firstSubmission) {
            setLanguage(firstSubmission.language);
            setCode(firstSubmission.submission);
          } else {
            setCode(boilerPlates[language]);
          }
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [selectedModuleId]);

  // Load new question and maintain language if there's submission data
  useEffect(() => {
    const currentQuestion = questions[currentQuestionIndex];
    const submission = currentQuestion?.submission_data;
    if (submission) {
      setLanguage(submission.language);
      setCode(submission.submission);
    } else {
      setCode(boilerPlates[language]);
    }
  }, [currentQuestionIndex, questions]);

  const handleSubmit = async () => {
    const currentQuestion = questions[currentQuestionIndex];
    if (!currentQuestion) return;

    try {
      const submissionResponse = await api.post(`course/${selectedModuleId}/p/${currentQuestion.id}/`, {
        submission: code,
        language,
      });
      const updatedQuestions = questions.map((question, index) =>
        index === currentQuestionIndex
          ? {
              ...question,
              submitted: true,
              submission_data: {id: submissionResponse.id, language: language, submission:  code },
            }
          : question
      );
      setQuestions(updatedQuestions);

    } catch (error) {
      setError(error);
    }
  };

  const handleCodeChange = (value) => setCode(value);

  const handleNext = async () => {
    //await handleSubmit();
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = async () => {
    //await handleSubmit();
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };
  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  
    const currentQuestion = questions[currentQuestionIndex];
    const languageSubmission = currentQuestion?.submission_data
    if (languageSubmission?.language !== newLanguage) {
      setCode(boilerPlates[newLanguage]);
      return;
    }
    // Use the boilerplate code if no submission exists for the selected language
    if (languageSubmission) {
      setCode(languageSubmission.submission);
    }
  };
  
  const notify = (message) => {
    toast(message);
  };

  // Effect to handle displaying the toast message
  useEffect(() => {
    if (toastMessage) {
      notify(toastMessage);
      setToastMessage(null); // Clear the message after notifying
    }
  }, [toastMessage]);

  const handleRunCode = async () => {
    const currentQuestion = questions[currentQuestionIndex];
    if (!currentQuestion) return;
    try {
      // Step 1: Submit the code to get the submission ID
      const submissionResponse = await api.post(`course/${selectedModuleId}/p/${currentQuestion.id}/`, {
        submission: code,
        language,
      });

      const submissionId = submissionResponse.data.id; // Extract submission ID

      // Update the submission ID in the questions data
      const updatedQuestions = questions.map((question, index) =>
        index === currentQuestionIndex
          ? {
              ...question,
              submission_data: { ...question.submission_data, id: submissionId },
            }
          : question
      );
      setQuestions(updatedQuestions);

      // Step 2: Prepare the payload for the run-code API
      const payload = {
        input: showCustomInput ? customInput : "",
        is_custom_testcase: showCustomInput,
        submission_id: submissionId,
      };

      // Step 3: Call the run-code API
      const runCodeResponse = await api.post(`course/${selectedModuleId}/run-code/${currentQuestion.id}/`, payload);

      // Step 4: Set the toast message
      setToastMessage(runCodeResponse.data.message);

      // Step 5: If the response is successful, start polling for the status
      if (runCodeResponse.status >= 200 && runCodeResponse.status < 300) {
        const pollingDuration = 5 * 60 * 1000; // 5 minutes in milliseconds
        const pollingInterval = 10 * 1000; // 10 seconds in milliseconds
        const endTime = Date.now() + pollingDuration;

        const intervalId = setInterval(async () => {
          try {
            const statusResponse = await api.get(`course/${selectedModuleId}/run-status/${currentQuestion.id}/`);
            // Handle the status response if needed

            // Check if the status is not  "COMPLETED"
            if (statusResponse.data.status !== "judging") {
              clearInterval(intervalId);
              setToastMessage("Code execution completed.");
            }

            // Stop polling if the time limit is reached
            if (Date.now() >= endTime) {
              clearInterval(intervalId);
            }
          } catch (error) {
            clearInterval(intervalId);
            if (error.response) {
              setToastMessage(error.response.data.message || "An error occurred while checking status.");
            } else {
              setToastMessage("An error occurred while checking status.");
            }
          }
        }, pollingInterval);
      }
    } catch (error) {
      // Handle errors and display the error message
      if (error.response) {
        setToastMessage(error.response.data.message || "An error occurred.");
      } else {
        setToastMessage("An error occurred.");
      }
    }
  };
  
  const handleTestCases = async () => {
    const currentQuestion = questions[currentQuestionIndex];
    if (!currentQuestion) return;
    const submissionId = 21 // currentQuestion.submission_data?.[0]?.id;

    if (!submissionId) {
      setToastMessage("No submission found.");
      return;
    }

    try {
      const response = await api.get(`course/${selectedModuleId}/submission-test-case-run-status/${currentQuestion.id}/`);
      const testCaseData = response.data;
      setTestCases(testCaseData);
      setIsModalOpen(true);
    } catch (error) {
      setToastMessage("An error occurred.");
    }
  };


  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTestCase(null);
  };

  if (loading) return <Loader />;
  if (error) return <div className="error-message"><p>{error.message}</p></div>;

  const currentExtension = languageExtensions[language] || python();


  return (
    <div>
      <NavigationBar
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions.length}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
      <Divider sx={{ borderColor: "black", borderWidth: "1px", width: "100%", marginBottom: "0px" }} />
      <Split className="split" minSize={500} sizes={[50, 50]}>
        <div className="split-pane">
          <ProblemDiscription problem={questions[currentQuestionIndex]} />
        </div>
        <div className="split-pane-code">
          <div className="playground-container">
            <PreferenceNav 
              setLanguage={handleLanguageChange} 
              initialLanguage={language}
              theme={theme}
              setTheme={setTheme}
            />
            
            
            <div className="code-editor">
              <CodeMirror
                key={`${language}-${currentQuestionIndex}`}
                value={code}
                theme={theme === 'dark' ? vscodeDark : vscodeLight}
                extensions={[currentExtension]}
                style={{ fontSize: 16, height: "calc(75vh - 2.75rem)" }}
                onChange={handleCodeChange}
              />
            </div>
            <div className="bottom-container">
              <div className="controls-row">
                <label className="custom-input-label">
                  <input 
                    type="checkbox" 
                    checked={showCustomInput}
                    onChange={(e) => setShowCustomInput(e.target.checked)}
                  />
                  Custom Input
                </label>
                <div className="playground-button-container">
                  <button className="button test-cases-button" onClick={handleTestCases}>Test cases</button>
                  <button className="button run-button" onClick={handleRunCode}>Run Code</button>
                  <button className="button" onClick={handleSubmit}>Save</button>
                </div>
              </div>
              {showCustomInput && (
                <textarea
                  className="custom-input-textarea"
                  value={customInput}
                  onChange={(e) => setCustomInput(e.target.value)}
                  placeholder="Enter your test cases here..."
                />
              )}
            </div>
          </div>
        </div>
      </Split>
      <ToastContainer />
      <TestCaseModal
        isOpen={isModalOpen}
        onClose={closeModal}
        testCases={testCases}
      />
    </div>
  );

};


export default Workspace;
