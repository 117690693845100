import React from "react";
import { Box, Typography, Card, CardContent, Avatar, Tooltip } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Testimonial data
const jobTestimonials = [
  {
    name: "Prashant Keshri",
    field: "Data Science",
    company: "Jakson Green",
    feedback:
      "Edgent transformed my career! With no coding experience, I became a skilled data scientist through their program. After my BTech from IIT Dhanbad and MTech, I was lost, but Edgent guided me. Now, I'm an Assistant Manager in Data Science at Jakson Green. Highly recommend Edgent for career growth!",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/prashant.jpeg",
  },
  {
    name: "Pushkar Raj",
    field: "Data Science",
    company: "Neuralgo.ai",
    feedback:
      "Edgent turned my career around, taking me from an IAS aspirant to a Senior Data Scientist at Ascendum Solutions. Despite my lack of coding experience, their outstanding teaching and referral programs were pivotal to my success. I highly recommend Edgent for anyone seeking to enter the tech industry.",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/pushkar.jpeg",
  },
  {
    name: "Preethi P",
    field: "SDE",
    company: "Aris Investing Private Limited",
    feedback:
      "Edgent significantly boosted my career right after college. Their exceptional teaching and referral programs secured me a Senior Frontend Developer role at Aris Investing Private Limited. I highly recommend Edgent for anyone aiming to excel in the tech industry.",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/preethi.jpeg",
  },
  {
    name: "Deepak Sharma", // remove this
    field: "SDE",
    company: "Perfios",
    feedback:
      "Edgent transformed my career from Support Engineer to Senior Software Development Engineer at Perfios. Their comprehensive program and excellent mentorship gave me the skills and confidence to succeed in software development. I highly recommend Edgent for anyone seeking a significant career change.",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/deepak.jpeg",
  },
  {
    name: "Nikhil Gupta", // remove this
    field: "Data Science",
    company: "Tech Mahindra",
    feedback:
      "Edgent helped me successfully transition into Data Science at Tech Mahindra. Their comprehensive program and mentorship equipped me with essential skills in advanced analytics, machine learning, and data interpretation. I highly recommend Edgent for anyone aspiring to thrive in the dynamic field of data science.",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/nikhil_gupta.jpeg",
  },
];

// Startup testimonial data
const startupTestimonials = [
  {
    name: "Gensys",
    field: "Innovative AI-powered Tool",
    feedback:
      "Gensys is an innovative AI-powered tool that transforms your textual prompts into captivating short videos—perfect for travel blogs, storytelling, marketing, and creative expressions. Unleash your imagination effortlessly!",
    photo:
      "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/gensys.jpeg",
  },
];

const MentorshipSection = () => {
  const settingsForLargeScreens = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3, // 3 cards on large screens
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // 2 cards on medium screens (like tablets)
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // 1 card on smaller screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsForSmallerScreens = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1, // 1 card on smaller screens
    slidesToScroll: 1,
  };

  return (
    <Box
      sx={{
        py: 6,
        paddingBottom: "100px",
        width: "100%",
        px: 4,
      }}
    >
      {/* Edgent Job Stories Section */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          color: "#0181BB",
          fontWeight: 600,
          mb: 6,
          fontFamily: "Be Vietnam Pro",
          fontSize: { xs: "32px", sm: "36px", md: "52px" },
        }}
      >
        Edgent Job Stories
      </Typography>
      <Slider {...settingsForLargeScreens}>
        {jobTestimonials.map((testimonial, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              p: { xs: 1, sm: 2 },
            }}
          >
            <Card
              sx={{
                borderRadius: "8px",
                border: "1px solid #D5D5D5",
                padding: { xs: "16px", sm: "20px", md: "24px" },
                fontFamily: "Be Vietnam Pro",
                height: { xs: "400px", md: "350px" },
                width: { xs: "100%", sm: "100%", md: "100%" },
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                boxSizing: "border-box",
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar
                    src={testimonial.photo}
                    sx={{
                      width: { xs: 56, sm: 64 },
                      height: { xs: 56, sm: 64 },
                      mr: 2,
                    }}
                  />
                  <Box textAlign="left">
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontFamily: "Be Vietnam Pro",
                        fontWeight: 600,
                        fontSize: { xs: "16px", sm: "18px" },
                      }}
                    >
                      {testimonial.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{
                        fontFamily: "Be Vietnam Pro",
                        fontSize: { xs: "14px", sm: "16px" },
                      }}
                    >
                      {testimonial.field}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{
                        fontFamily: "Be Vietnam Pro",
                        fontSize: { xs: "12px", sm: "14px" },
                        fontWeight: 600,
                      }}
                    >
                      {testimonial?.company}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                    fontFamily: "Be Vietnam Pro",
                    textAlign: "left",
                  }}
                >
                  {testimonial.feedback}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Slider>

      {/* Edgent Startup Stories Section */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          color: "#0181BB",
          fontWeight: 600,
          my: 6,
          fontFamily: "Be Vietnam Pro",
          fontSize: { xs: "32px", sm: "36px", md: "52px" },
        }}
      >
        Edgent Projects / Research
      </Typography>
      {startupTestimonials.map((testimonial, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <Card
            sx={{
              borderRadius: "8px",
              border: "1px solid #D5D5D5",
              padding: "24px",
              fontFamily: "Be Vietnam Pro",
              height: "auto",
              width: "100%",
              maxWidth: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxSizing: "border-box",
              textAlign: "center",
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar
                  src={testimonial.photo}
                  sx={{
                    width: 64,
                    height: 64,
                    mr: 2,
                  }}
                />
                <Box textAlign="left">
                  {/* Tooltip */}
                  <Tooltip title="Upcoming" arrow>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontFamily: "Be Vietnam Pro",
                        fontWeight: 600,
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      {testimonial.name}
                    </Typography>
                  </Tooltip>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{
                      fontFamily: "Be Vietnam Pro",
                      fontSize: "14px",
                    }}
                  >
                    {testimonial.field}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{
                      fontFamily: "Be Vietnam Pro",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {testimonial.company}
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  fontFamily: "Be Vietnam Pro",
                  textAlign: "left",
                }}
              >
                {testimonial.feedback}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      ))}
    </Box>
  );
};
export default MentorshipSection;