import ChatList from "./components/ChatList";
import NavbarLoggedInNew from "components/Navbar-new/NavbarLoggedInNew";
import Chat from "./components/Chat";
import "./index.css";
import { useState } from "react";
import { UserProvider } from "./context/UserContext";

const ChatApp = () => {
  const [selectedChat, setSelectedChat] = useState(null);

  const handleChatSelection = (chat) => {
    setSelectedChat(chat);
  };

  const handleBackClick = () => {
    setSelectedChat(null);
  };

  return (
    <UserProvider>
      <div className="main2">
        <NavbarLoggedInNew />
        <div className={`main22 ${selectedChat ? "chat-visible" : ""}`}>
          <ChatList setSelectedChat={handleChatSelection} />
          <Chat selectedChat={selectedChat} handleBackClick={handleBackClick} />
        </div>
      </div>
    </UserProvider>
  );
};

export default ChatApp;
