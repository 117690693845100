import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { useUser } from "../../context/UserContext.js";
import api from "../../../api";
import SuccessToast from "components/Toaster/SuccesToast";
import ErrorToast from "components/Toaster/ErrorToast";
import NavbarLoggedInNew from "components/Navbar-new/NavbarLoggedInNew";

function Settings() {

  
  const [error, setError] = useState(null);
  const { data } = useUser();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    current_profession: "",
    target_profession: "",
    email: "",
    phone_number: "",
    college_name: "",
    years_of_experience: "",
  });
  useEffect(() => {
    if (data) {
      setFormData({
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        current_profession: data.current_profession || "",
        target_profession: data.target_profession || "",
        email: data.email || "",
        phone_number: data.phone_number || "",
        college_name: data.college_name || "",
        years_of_experience: data.years_of_experience || "",
      });
    }
  }, [data]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset any existing toasts
    setShowSuccessToast(false);
    setShowErrorToast(false);
    
    try {
        const response = await api.post("user-profile/", formData);

        if (response.status === 201) {
            setShowSuccessToast(true);
            setError(null);
            // Auto-hide success toast after 3 seconds
            setTimeout(() => setShowSuccessToast(false), 3000);
        } else {
            setError("Failed to update profile.");
            setShowErrorToast(true);
            // Auto-hide error toast after 3 seconds
            setTimeout(() => setShowErrorToast(false), 3000);
        }
    } catch (err) {
        console.error("Error updating profile:", err);
        setError("An error occurred while updating your profile.");
        setShowErrorToast(true);
        // Auto-hide error toast after 3 seconds
        setTimeout(() => setShowErrorToast(false), 3000);
    }
};




  return (
    <>
      <NavbarLoggedInNew hideMenu={false} />
      <div className={styles.settings}>
        {showSuccessToast && (
          <SuccessToast message="Profile updated successfully!" />
        )}
        {showErrorToast && <ErrorToast message={error} />}
        <div className={styles.bottom}>
         
          

          <div className={styles.leftSection}>
            <div classname={styles.profileName}>
              <h2 className={styles.profile}>Profile</h2>
            </div>
            <div classname={styles.new}>
              {data.avatar != null ? (
                <img
                  className="image"
                  src={data.avatar}
                  alt={data?.first_name ? `${data.first_name}` : ""}
                />
              ) : (
                <div className={styles.alter}>
                  <span className={styles.span}>
                    {data.first_name ? data.first_name.charAt(0) : ""}
                  </span>
                </div>
              )}
            </div>

            <h1 className={styles.name2}>{`${data?.first_name || ""} ${
              data?.last_name || ""
            }`}</h1>
            <p className={styles.location2}>
              {data?.state ? `${data.state}` : ""}
              {data?.state && data?.country ? ", " : ""}
              {data?.country ? `${data.country}` : ""}
            </p>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.formHeader}>
              <h2 className={styles.formTitle}>BASIC INFO</h2>
              <div className={styles.formActions}>
                
                <button
                  type="submit"
                  className={styles.saveButton}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
            <hr className={styles.divider} />
            <form>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="first_name"
                    value={formData.first_name || ""}
                    disabled
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="last_name"
                    value={formData.last_name || ""}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="currentProfession">Current Profession</label>
                  <input
                    type="text"
                    id="currentProfession"
                    name="current_profession"
                    value={formData.current_profession || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="targetProfession">Target Profession</label>
                  <input
                    type="text"
                    id="targetProfession"
                    name="target_profession"
                    value={formData.target_profession}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    disabled
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="number"
                    id="phoneNumber"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="college">College</label>
                  <input
                    type="text"
                    id="college"
                    name="college_name"
                    value={formData.college_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="yearOfExperience">Years Of Experience</label>
                  <input
                    type="number"
                    id="yearOfExperience"
                    name="years_of_experience"
                    value={formData.years_of_experience}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
