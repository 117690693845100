import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorToast from "components/Toaster/ErrorToast";
import api from "../api";

// MenteeCard Component to display individual mentee details
const MenteeCard = ({ mentee }) => {
  const {
    id,
    first_name,
    last_name,
    country,
    state,
    name,
    description,
    timestamp,
    progress,
  } = mentee;
  const navigate = useNavigate();

  const fullName = `${first_name} ${last_name}`.trim();
  const location =  `${state}, ${country}`.trim();
  const startDate = new Date(timestamp).toLocaleDateString();

  const handleViewButtonClick = () => {
    navigate(`/dashboard/workspace/${id}`);
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card
        sx={{
          borderRadius: "10px",
          border: "1px solid #E8E8E8",
          height: "100%",
          boxShadow: "none",
          p: 2,
          mb: 2,
        }}
      >
        <CardContent sx={{ p: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {mentee?.avatar ? (
                <Avatar
                  sx={{ width: 40, height: 40 }}
                  src={mentee?.avatar}
                  alt={fullName}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: "#0181BB",
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  {fullName?.charAt(0)?.toUpperCase()}
                </Avatar>
              )}
              <Box sx={{ ml: 2 }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  {fullName}
                </Typography>
                <Typography
                  sx={{
                    color: "#595A5F",
                    fontSize: "14px",
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  {location}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{
                  color: "#595A5F",
                  fontSize: "14px",
                  textAlign: "right",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                Started
              </Typography>
              <Typography
                sx={{
                  color: "#595A5F",
                  fontSize: "14px",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                {startDate}
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              color: "#202124",
              fontSize: "18px",
              fontWeight: 500,
              mb: 2,
              fontFamily: "Be Vietnam Pro",
            }}
          >
            {name}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                flex: 1,
                height: 8,
                borderRadius: 4,
                backgroundColor: "#E8E8E8",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#0181BB",
                },
              }}
            />
            <Typography
              sx={{
                color: "#595A5F",
                fontSize: "14px",
                minWidth: "70px",
                fontFamily: "Be Vietnam Pro",
              }}
            >
              {`${progress?.toFixed(0)}%`}
            </Typography>
          </Box>

          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "#0181BB",
              color: "white",
              textTransform: "capitalize",
              borderRadius: "6px",
              fontFamily: "Be Vietnam Pro",
              fontSize: "14px",
              mt: 1,
              "&:hover": {
                backgroundColor: "#0181BB",
              },
            }}
            onClick={handleViewButtonClick}
          >
            View
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};

// MenteeCardList Component to fetch and display list of mentees
const MenteeCardList = () => {
  const [menteeData, setMenteeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const accessToken = localStorage.getItem("access_token");
  const userProfile = useSelector((state) => state.userProfile);
  const userRole = userProfile?.data?.role;

  const fetchMenteeData = async () => {
    try {
      const response = await api.get("dashboard/");
      setMenteeData(response?.data);
      localStorage.setItem("menteeData", JSON.stringify(response?.data));
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
        <ErrorToast message="Your session has expired. Please log in again." />;
      } else {
        <ErrorToast message={"Failed to fetch mentee data"} />;
      }
      setError("Failed to fetch mentee data");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userRole === "mentor") {
      fetchMenteeData();
    }
  }, [accessToken]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const menteeArray = Array.isArray(menteeData) ? menteeData : [menteeData];

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: "Be Vietnam Pro",
        }}
      >
        <Typography>{error}</Typography>
      </Box>
    );
  }

  if (!menteeData || menteeData?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
          fontFamily: "Be Vietnam Pro",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Be Vietnam Pro",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          No Data Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            fontFamily: "Be Vietnam Pro",
          }}
        >
          My Students
        </Typography>
        
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            sx={{
              backgroundColor: "#0181BB",
              color: "white",
              textTransform: "capitalize",
              fontFamily: "Be Vietnam Pro",
              "&:hover": {
                backgroundColor: "#0181BB",
              },
            }}
          >
            Ongoing
          </Button>
          <Button
            sx={{
              backgroundColor: "#E8E8E8",
              color: "#595A5F",
              textTransform: "capitalize",
              fontFamily: "Be Vietnam Pro",
            }}
          >
            Completed
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {menteeArray?.map((mentee) => (
          <MenteeCard key={mentee?.id} mentee={mentee} />
        ))}
      </Grid>
    </Box>
  );
};

export default MenteeCardList;
