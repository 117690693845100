import React from "react";
import { Link } from "react-router-dom";
import "./login.css";
import GoogleAuth from "./components/GoogleAuth";
import edgentLogo from "../../assets/svgImages/edgentLogo.svg";

export default function Login() {
  return (
    <div className="Auth-form-container">
      <div className="left-section">
        <h1>1:1 Mentorship</h1>
        <h2>Tailored for Success</h2>
        <p>Unlock your dream career through a personalized mentorship program</p>
      </div>
      
      <div className="right-section">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3>Welcome Back</h3>
            <p className="sign-in-text">Sign in to continue to your account</p>
            <div className="w-100">
              <GoogleAuth />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
