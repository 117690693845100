import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AuthProvider } from "./AuthContext"; // Import AuthProvider
import Login from "Homepage/Login";
import PrivateRoutes from "./PrivateRoute";
import { Provider } from "react-redux";
import { store } from "./store";
import AboutUs from "Homepage/AboutUs/AboutUs";
import Dashboard from "dashboard";
import Workspace from "workspace";
import ChatApp from "../src/Chat/App.js";
import Profile from "../src/Profile/App.js";
import CalendarMeeting from "new-homepage/CalendarMeeting";
import PageNotFound from "PageNotFound";
import HomepageNew from "homepage-new/Homepage";
import HandBookTemplate from "homepage-new/HandBookTemplate";

const edgentData = {
  blogs: [
    {
      title: "Roadmap to Become AI Scientist",
      id: "roadmap-to-become-ai-scientist",
      url: "https://edgentai-public-data.s3.ap-south-1.amazonaws.com/Roadmap.md",
    },
    {
      title: "But What is Generative AI ~ Really?",
      id: "generative-ai-really",
      url: null
    },
    {
      title: "Roadmap to Master Generative AI in Natural Language",
      id: "master-gen-ai-nlp",
      url: null
    },
    {
      title: "Roadmap to Master Generative AI in Computer Vision",
      id: "master-gen-ai-cv",
      url: null
    },
    {
      title: "Practical Skills Needed to Get in a Job",
      id: "practical-skills-job",
      url: null
    },
  ],
  caseStudies: [
    {
      title: "Finetune a Yolo Model for Object Detection",
      id: "finetune-yolo-model",
      url: null
    },
    {
      title: "Finetune a Llama Model for Hierarchical Classification",
      id: "finetune-llama-model",
      url: null
    },
    { title: "How to Create a Multimodal RAG", id: "multimodal-rag", url: null},
    {
      title: "Microsoft GraphRag for Production",
      id: "microsoft-graphrag-production",
      url: null
    },
    { title: "SOM Prompting", id: "som-prompting", url: null},
  ],
  startups: [{ title: "Text to Video", id: "text-to-video", url: null}],
};

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* <Route path="/" element={<NewHomepage />} /> */}
          <Route path="/" element={<HomepageNew />} />
          <Route
            path="/blogs"
            element={<HandBookTemplate toc={edgentData.blogs} />}
          />
          <Route
            path="/case-studies"
            element={<HandBookTemplate toc={edgentData.caseStudies} />}
          />
          <Route
            path="/startups"
            element={<HandBookTemplate toc={edgentData.startups} />}
          />{" "}
          <Route path="/page-not-found" element={<PageNotFound />} />
          <Route path="/login" element={<Login />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard/workspace/:id" element={<Workspace />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/calendar-meeting" element={<CalendarMeeting />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/chat" element={<ChatApp />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);
