import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  Link as MuiLink,
  Typography,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ReactMarkdown from "react-markdown";
import rehypeSlug from "rehype-slug"; // For adding id attributes to headings
import Loader from "../components/Loader";
import ErrorToast from "components/Toaster/ErrorToast";

// Function to create TOC by parsing markdown headers
const generateTOC = (content) => {
  // Regular expression to match numbered headings and sub-numbered headings
  const regex =
    /^(#{1,6})\s(?:Chapter\s\d+|\d+\.\s[A-Z].*|[A-Z]\.\s[A-Z].*|[\dA-Z]+\.\s.+)$/gm;
  const toc = [];
  let match;

  while ((match = regex.exec(content)) !== null) {
    const level = match[1].length; // Number of '#' determines the level
    const title = match[0].replace(/^#{1,6}\s/, ""); // Remove heading markers
    const id = title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "");
    const reading_material_url = `/section/${id}`; // Generate a unique URL for each section
    toc.push({ level, title, id, reading_material_url });
  }

  return toc;
};

const ReadingMaterial = ({ course }) => {
  const [markdownContent, setMarkdownContent] = useState("");
  const [toc, setToc] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedSections, setExpandedSections] = useState({});
  const contentRef = useRef(null);

  useEffect(() => {
    if (course?.reading_material_url) {
      const fetchMarkdownContent = async () => {
        try {
          const response = await fetch(course.reading_material_url);
          const data = await response.text();
          setMarkdownContent(data);
          setToc(generateTOC(data)); // Generate TOC based on markdown content
        } catch (error) {
          <ErrorToast message={`Error fetching Markdown file: ${error}`} />;
        } finally {
          setLoading(false);
        }
      };

      fetchMarkdownContent();
    } else {
      setLoading(false);
    }
  }, []);

  const handleSmoothScroll = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      const offset = 80;
      const elementPosition = targetElement.offsetTop - offset;
      contentRef.current.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };

  const toggleSection = (id) => {
    setExpandedSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderTOC = () => {
    const groupedTOC = toc?.reduce((acc, item) => {
      const parent = acc[acc.length - 1];
      if (item.level === 1 || item.level === 2) {
        acc?.push({ ...item, children: [] });
      } else if (parent) {
        parent?.children?.push(item);
      }
      return acc;
    }, []);

    return (
      <List sx={{ paddingLeft: 0, fontFamily: "Be Vietnam Pro" }}>
        {groupedTOC?.map((item, index) => (
          <ListItem key={index} sx={{ display: "block", paddingLeft: "8px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {item?.children?.length > 0 && (
                <IconButton
                  onClick={() => toggleSection(item.id)}
                  size="small"
                  sx={{
                    padding: "0px",
                    marginRight: "8px",
                    "& svg": {
                      fontSize: "1rem",
                    },
                  }}
                >
                  {expandedSections[item.id] ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              )}
              <MuiLink
                onClick={(e) => {
                  e.preventDefault();
                  handleSmoothScroll(item.id);
                }}
                underline="hover"
                component="button"
                sx={{
                  fontSize: "0.875rem",
                  fontFamily: "Be Vietnam Pro",
                  textAlign: "left",
                }}
              >
                {item.title}
              </MuiLink>
            </Box>

            {expandedSections[item.id] && item?.children?.length > 0 && (
              <List sx={{ paddingLeft: "16px" }}>
                {item.children.map((subItem, subIndex) => (
                  <ListItem
                    key={subIndex}
                    sx={{ paddingY: "2px", paddingLeft: "8px" }}
                  >
                    <MuiLink
                      href={subItem.reading_material_url} // Use reading_material_url for navigation
                      underline="hover"
                      sx={{
                        fontSize: "0.875rem",
                        fontFamily: "Be Vietnam Pro",
                        textAlign: "left",
                      }}
                    >
                      {subItem.title}
                    </MuiLink>
                  </ListItem>
                ))}
              </List>
            )}
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <>
      {!course.reading_material_url ? (
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Be Vietnam Pro",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          No data available.
        </Typography>
      ) : (
        <Grid container>
          <Grid item xs={3}>
            <Box
              sx={{
                padding: "8px",
                backgroundColor: "#f9f9f9",
                height: "100vh",
                overflowY: "auto",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "1rem", fontFamily: "Be Vietnam Pro" }}
              >
                Table of Contents
              </Typography>
              {renderTOC()}
            </Box>
          </Grid>

          <Grid item xs={9}>
            <Box
              ref={contentRef}
              sx={{
                padding: "16px",
                maxHeight: "100vh",
                overflowY: "auto",
                paddingRight: "24px",
              }}
            >
              {loading ? (
                <Loader />
              ) : (
                <Box
                  sx={{
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    maxWidth: "100%",
                  }}
                >
                  <ReactMarkdown
                    children={markdownContent}
                    rehypePlugins={[rehypeSlug]}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ReadingMaterial;
