import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import SuccessModal from "components/SuccessModal";
import Toaster from "../components/Toaster/Toaster";
import api from "../api";
import ErrorToast from "components/Toaster/ErrorToast";
import DownloadIcon from "@mui/icons-material/Download"; // Import the download icon

const Assignment = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState("error");

  const selectedModuleId = useSelector(
    (state) => state.module.selectedModuleId
  );
  const userProfile = useSelector((state) => state.userProfile);
  const userRole = userProfile?.data?.role;

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const endpoint =
          userRole === "mentor"
            ? `course/${selectedModuleId}/mentor/assignments/`
            : `course/${selectedModuleId}/mentee/assignments/`;
  
        const response = await api.get(endpoint);
  
        const fetchedAssignments = response?.data;
        setAssignments(fetchedAssignments);

        // Find the index of the assignment that is assigned and set selectedIndex
        const assignedIndex = fetchedAssignments.findIndex(
          (assignment) => assignment.assigned
        );
        setSelectedIndex(assignedIndex !== -1 ? assignedIndex : null);

      } catch (error) {
        setToasterMessage("Failed to fetch assignments");
        setToasterSeverity("error");
        setToasterOpen(true);
      } finally {
        setLoading(false);
      }
    };
  
    fetchAssignments();
  }, [selectedModuleId, userRole]);

  const handleCheckboxChange = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex(null); // Uncheck if the same checkbox is clicked
    } else {
      setSelectedIndex(index); // Check the new checkbox
    }
  };

  const handleAssign = async () => {
    // Check if assignments array is empty
    if (!assignments || assignments.length === 0) {
      setToasterMessage("No assignments to assign");
      setToasterSeverity("warning");
      setToasterOpen(true);
      return;
    }
  
    const updatedAssignments = assignments?.map((assignment, index) => ({
      ...assignment,
      assigned: index === selectedIndex, // Assign the selected assignment
    }));

    try {
      const endpoint =
        userRole === "mentor"
          ? `course/${selectedModuleId}/mentor/assignments/`
          : `course/${selectedModuleId}/mentee/assignments/`;

      await api.post(endpoint, updatedAssignments);

      setSuccessMessage("Assignment Successfully Assigned!");
      setIsSuccessModalOpen(true);
      setToasterMessage("Assignment Successfully Assigned!");
      setToasterSeverity("success");
      setToasterOpen(true);
    } catch (error) {
      setToasterMessage("Failed to assign assignments");
      setToasterSeverity("error");
      setToasterOpen(true);
    }
  };

  const handleDownload = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Revoke the object URL after download
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      <ErrorToast message={`Error downloading the file: ${error}`} />;
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      {assignments?.length === 0 ? (
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Be Vietnam Pro",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            No assignments assigned by the mentor.
          </Typography>
        </Grid>
      ) : (
        assignments?.map((assignment, index) => (
          <Grid item xs={12} key={assignment.id}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 3,
                mb: index === assignments.length - 1 ? "90px" : 0,
              }}
            >
              <Checkbox
                checked={index === selectedIndex}
                onChange={() => handleCheckboxChange(index)}
                color="primary"
              />
              <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontFamily: "Be Vietnam Pro" }}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDownload(
                        assignment?.reference_url,
                        assignment?.title
                      );
                    }}
                    style={{
                      color: "#0181BB",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DownloadIcon sx={{ color: "#0181BB", fontSize: "20px" }} />
                  </a>
                </Typography>
              <Box sx={{ ml: 2, flex: 1 }}>
                <Typography variant="h6" sx={{ fontFamily: "Be Vietnam Pro" }}>
                  {assignment?.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontFamily: "Be Vietnam Pro" }}
                >
                  {assignment?.description}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))
      )}
      <Grid item xs={12}>
        <Box
          display={"flex"}
          backgroundColor="#F1F5F6"
          justifyContent="space-between"
          position={"fixed"}
          bottom={0}
          right={0}
          width={"90%"}
          height={"90px"}
          alignItems={"center"}
          p={2}
        >
          <Box
            sx={{
              fontSize: { xs: "10px", sm: "12px", md: "16px" },
              color: "#202124",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "16px",
                  fontFamily: "Be Vietnam Pro",
                },
              }}
            >
              Total Assignment Questions Selected:
              {selectedIndex !== null ? 1 : 0}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "16px",
                  fontFamily: "Be Vietnam Pro",
                },
              }}
            >
              Total Assignment Questions available: {assignments.length}
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={handleAssign}
              sx={{
                backgroundColor: "#0181BB",
                color: "white",
                textTransform: "capitalize",
                zIndex: 10000,
                fontFamily: "Be Vietnam Pro",
                "&:hover": {
                  backgroundColor: "#01568C",
                  boxShadow: "none",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
            >
              Assign
            </Button>

            <SuccessModal
              open={isSuccessModalOpen}
              onClose={() => setIsSuccessModalOpen(false)}
              message={successMessage}
            />

            <Toaster
              open={toasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onClose={() => setToasterOpen(false)}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Assignment;
