import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Chip,
  Grid,
  Button,
  Card,
  CardContent,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Loader from "../components/Loader";
import Toaster from "components/Toaster/Toaster";
import SuccessModal from "components/SuccessModal";
import ErrorToast from "components/Toaster/ErrorToast";
import api from "../api";
import { useSelector } from "react-redux";

const CreateNewAssessment = ({ course, onClose, onSuccess }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [chipLabels, setChipLabels] = useState([]);
  const [fetchingChips, setFetchingChips] = useState(true);
  const [submitToastOpen, setSubmitToastOpen] = React.useState(false);
  const [submitMessage, setSubmitMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const selectedModuleId = useSelector(
    (state) => state.module.selectedModuleId
  );

  const questionCategories = [
    {
      type: "MCQ",
      levels: [
        { difficulty: "Easy", count: 0 },
        { difficulty: "Moderate", count: 0 },
        { difficulty: "Difficult", count: 0 },
      ],
    },
    {
      type: "Subjective",
      levels: [
        { difficulty: "Easy", count: 0 },
        { difficulty: "Moderate", count: 0 },
        { difficulty: "Difficult", count: 0 },
      ],
    },
    {
      type: "Programming",
      levels: [
        { difficulty: "Easy", count: 0 },
        { difficulty: "Moderate", count: 0 },
        { difficulty: "Difficult", count: 0 },
      ],
    },
  ];

  const [selectedChips, setSelectedChips] = useState([]);
  const [isScreenOne, setIsScreenOne] = useState(true);
  const [isGenerateAssignmentClicked, setIsGenerateAssignmentClicked] =
    useState(false);

  const [questionCounts, setQuestionCounts] = useState(() => {
    const initialCounts = {};
    // Initialize question counts based on selected chips
    selectedChips.forEach((category) => {
      initialCounts[category] = {
        MCQ: { Easy: 0, Moderate: 0, Difficult: 0 },
        Subjective: { Easy: 0, Moderate: 0, Difficult: 0 },
        Programming: { Easy: 0, Moderate: 0, Difficult: 0 },
      };
    });
    return initialCounts;
  });

  useEffect(() => {
    const updatedCounts = { ...questionCounts };
    selectedChips.forEach((category) => {
      if (!updatedCounts[category]) {
        updatedCounts[category] = {
          MCQ: { Easy: 0, Moderate: 0, Difficult: 0 },
          Subjective: { Easy: 0, Moderate: 0, Difficult: 0 },
          Programming: { Easy: 0, Moderate: 0, Difficult: 0 },
        };
      }
    });
    setQuestionCounts(updatedCounts);
  }, [selectedChips]);

  // Handle increment of question counts
  const handleIncrement = (category, difficulty, questionType) => {
    setQuestionCounts((prevCounts) => ({
      ...prevCounts,
      [category]: {
        ...prevCounts[category],
        [questionType]: {
          ...prevCounts[category][questionType],
          [difficulty]: prevCounts[category][questionType][difficulty] + 1,
        },
      },
    }));
  };

  const handleDecrement = (category, difficulty, questionType) => {
    setQuestionCounts((prevCounts) => ({
      ...prevCounts,
      [category]: {
        ...prevCounts[category],
        [questionType]: {
          ...prevCounts[category][questionType],
          [difficulty]: Math.max(
            prevCounts[category][questionType][difficulty] - 1,
            0
          ),
        },
      },
    }));
  };

  const handleChipClick = (label) => {
    if (label === "Select All") {
      // Toggle select all
      if (selectedChips.length === chipLabels.length - 1) {
        setSelectedChips([]);
      } else {
        setSelectedChips(
          chipLabels
            .filter((chip) => chip.label !== "Select All")
            .map((chip) => chip.label)
        );
      }
    } else {
      if (selectedChips.includes(label)) {
        setSelectedChips(selectedChips.filter((chip) => chip !== label));
      } else {
        setSelectedChips([...selectedChips, label]);
      }
    }
  };

  const handleNextClick = () => {
    setIsScreenOne(false);
  };

  useEffect(() => {
    const fetchChipLabels = async () => {
      setFetchingChips(true);

      try {
        const response = await api.get(`workspace/skills/${selectedModuleId}/`);
        const labels = response?.data?.map((skill) => ({
          label: skill.title,
          id: skill.id,
        }));
        setChipLabels([...labels, { label: "Select All", id: null }]);
      } catch (error) {
        <ErrorToast message={`Failed to generate skills: ${error}`} />;
      } finally {
        setFetchingChips(false);
      }
    };

    fetchChipLabels();
  }, [selectedModuleId]); // Ensure this re-runs when `selectedModuleId` changes

  const handleGenerateAssignmentClick = async () => {
    setLoading(true);

    // Ensure chipLabels are populated before proceeding
    if (!chipLabels.length) {
      setLoading(false);
      return;
    }

    // Map selected chips to skill ids and titles
    const selectedSkills = selectedChips
      .map((label) => {
        const skill = chipLabels.find((chip) => chip.label === label);
        return skill ? { id: skill.id, title: skill.label } : null;
      })
      .filter((skill) => skill !== null);

    // Prepare data for assignment generation
    const data = {
      p: selectedSkills.map(({ id, title }) => {
        const programming = questionCounts[title]?.Programming || {};
        return {
          skill_id: id,
          E: programming?.Easy || 0,
          M: programming?.Moderate || 0,
          H: programming?.Difficult || 0,
        };
      }),
      o: selectedSkills.map(({ id, title }) => {
        const mcq = questionCounts[title]?.MCQ || {};
        return {
          skill_id: id,
          E: mcq?.Easy || 0,
          M: mcq?.Moderate || 0,
          H: mcq?.Difficult || 0,
        };
      }),
      s: selectedSkills.map(({ id, title }) => {
        const subjective = questionCounts[title]?.Subjective || {};
        return {
          skill_id: id,
          E: subjective?.Easy || 0,
          M: subjective?.Moderate || 0,
          H: subjective?.Difficult || 0,
        };
      }),
    };

    // Check if any category exceeds the limit
    const exceedsQuestionLimit = (category) => {
      return data[category].some((item) => item.E + item.M + item.H > 5);
    };

    if (
      exceedsQuestionLimit("p") ||
      exceedsQuestionLimit("o") ||
      exceedsQuestionLimit("s")
    ) {
      setSnackbarOpen(true);
      setSnackbarMessage(
        "Each skill can have a maximum of 5 questions across easy, medium, and hard for each category."
      );
      setSnackbarSeverity("error");
      setLoading(false);
      return;
    }

    try {
      // Call the API to generate the assessment
      await api.post(`course/${course?.id}/generate-assessment/`, data);

      setSubmitMessage("Assessment generated successfully!");
      setSnackbarSeverity("success");
      setSubmitToastOpen(true);
      setSuccessMessage("Assessment Generated Successfully!");
      setIsSuccessModalOpen(true);
      setIsGenerateAssignmentClicked(true);

      // Call onSuccess after 2 seconds
      setTimeout(() => {
        if (onSuccess) {
          onSuccess();
        }
      }, 2000);

      // Close the drawer after success
      setTimeout(() => {
        if (onClose) {
          onClose();
        }
      }, 5000);

    } catch (error) {
      setSubmitMessage("Error generating assessment.");
      setSnackbarSeverity("error");
      setSubmitToastOpen(true);
    } finally {
      setLoading(false);
    }
  };

  if (fetchingChips) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const totalQuestions = questionCategories.reduce((total, category) => {
    return total + category.levels.reduce((sum, level) => sum + level.count, 0);
  }, 0);

  return (
    <Grid container>
      <Grid item xs={12}>
        {isScreenOne ? (
          <>
            <Box sx={{ width: "100%" }}>
              <Typography my={2} fontFamily={"Be Vietnam Pro"}>
                Select Sub Topics
              </Typography>
              <Box sx={{ width: { xs: "100%", sm: "80%", md: "60%" } }}>
                {chipLabels?.filter((chip) => chip.label !== "Select All")
                  ?.length === 0 ? (
                  <Typography
                    fontFamily={"Be Vietnam Pro"}
                    color="textSecondary"
                  >
                    No skills available
                  </Typography>
                ) : (
                  chipLabels?.map((chip, index) => (
                    <Chip
                      key={index}
                      label={chip.label}
                      variant="outlined"
                      sx={{
                        marginRight: 1,
                        marginBottom: 1,
                        fontFamily: "Be Vietnam Pro",
                        color:
                          selectedChips.includes(chip.label) ||
                          chip.label === "Select All"
                            ? "#0181BB"
                            : "#0181BB",
                        borderColor: selectedChips.includes(chip.label)
                          ? "#0181BB"
                          : "#E0E0E0",
                        cursor: "pointer",
                      }}
                      onClick={() => handleChipClick(chip.label)}
                      deleteIcon={
                        selectedChips?.includes(chip.label) && (
                          <CheckCircleOutlineIcon sx={{ color: "#0181BB" }} />
                        )
                      }
                    />
                  ))
                )}
              </Box>
            </Box>
            <Button
              onClick={handleNextClick}
              sx={{
                mt: 2,
                fontFamily: "Be Vietnam Pro",
                backgroundColor:
                  selectedChips?.length === 0 ? "#B0B0B0" : "#0181BB",
                color: "white",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor:
                    selectedChips?.length === 0 ? "#B0B0B0" : "#01568C",
                },
              }}
              disabled={selectedChips?.length === 0}
            >
              Next
            </Button>
          </>
        ) : (
          <>
            {isGenerateAssignmentClicked && !chipLabels ? (
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      width: "100% !important",
                      gap: "8px",
                      mt: 3,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#0181BB",
                        color: "white",
                        borderRadius: "50%",
                        width: "32px",
                        height: "32px",
                        textAlign: "center",
                        px: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      E
                    </Box>
                    <Typography
                      sx={{
                        border: "1px solid #D5D5D5",
                        borderRadius: "12px",
                        fontSize: "16px",
                        padding: "12px",
                        flex: 1,
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      You are working on a Dell Boomi process that pulls data
                      from a web service API, transforms it, and then pushes it
                      to a database. Your Boomi process has the following steps:
                      1. HTTP Client connector: Retrieve data from a web service
                      API. 2. Data Process Shape: Transform the retrieved data.
                      The HTTP Client connector and the Data Process Shape
                      appear to be working correctly. Which of the following
                      configurations in the Database connector could be causing
                      this issue?
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    backgroundColor="#F1F5F6"
                    justifyContent="space-between"
                    position={"absolute"}
                    bottom={0}
                    left={0}
                    width={"100%"}
                    height={"90px"}
                    alignItems={"center"}
                    p={2}
                  >
                    <Box
                      sx={{
                        fontSize: "16px",
                        color: "#202124",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      Total Assessment Questions: {totalQuestions}
                    </Box>
                    <Box>
                      <Button
                        sx={{
                          backgroundColor: "#0181BB",
                          color: "white",
                          textTransform: "capitalize",
                          zIndex: 10000,
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        Send
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {selectedChips?.map((category, index) => (
                    <Box
                      sx={{
                        width: "100%",
                        mt: 2,
                        mb: index === selectedChips.length - 1 ? "90px" : 0,
                      }}
                    >
                      <Card sx={{ width: "100% !important" }}>
                        <CardContent sx={{ backgroundColor: "#F0F0F0" }}>
                          <Typography
                            key={index}
                            sx={{
                              color: "#202124",
                              fontSize: "20px",
                              fontWeight: 600,
                              fontFamily: "Be Vietnam Pro",
                            }}
                          >
                            {category}
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            mt={2}
                            key={category}
                          >
                            <Card
                              sx={{
                                ".MuiPaper-root .MuiCard-root": {
                                  width: "100% !important",
                                },
                              }}
                            >
                              <CardContent
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                {["MCQ", "Subjective", "Programming"].map(
                                  (questionType) => (
                                    <Box
                                      key={questionType}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "spaceBetween",
                                        alignItems: "center",
                                        marginBottom: 2,
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          fontFamily: "Be Vietnam Pro",
                                          marginRight: 2,
                                          minWidth: "120px",
                                        }}
                                      >
                                        {questionType}
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          width: "100%",
                                        }}
                                      >
                                        {["Easy", "Moderate", "Difficult"].map(
                                          (difficulty) => (
                                            <Box
                                              key={difficulty}
                                              sx={{
                                                flex: 1,
                                                textAlign: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "14px",
                                                  color: "#595A5F",
                                                  fontFamily: "Be Vietnam Pro",
                                                }}
                                              >
                                                {difficulty} Questions
                                              </Typography>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <IconButton
                                                  size="small"
                                                  sx={{
                                                    backgroundColor: "#E5F1F6",
                                                    width: "40px",
                                                    height: "40px",
                                                    p: 1,
                                                    borderRadius: 0,
                                                    fontFamily:
                                                      "Be Vietnam Pro",
                                                  }}
                                                  onClick={() =>
                                                    handleDecrement(
                                                      category,
                                                      difficulty,
                                                      questionType
                                                    )
                                                  }
                                                >
                                                  <RemoveIcon />
                                                </IconButton>
                                                <Typography
                                                  variant="body1"
                                                  sx={{
                                                    p: 2,
                                                    fontSize: "16px",
                                                    fontFamily:
                                                      "Be Vietnam Pro",
                                                  }}
                                                >
                                                  {questionCounts[category]?.[
                                                    questionType
                                                  ]?.[difficulty] || 0}
                                                </Typography>
                                                <IconButton
                                                  size="small"
                                                  sx={{
                                                    backgroundColor: "#E5F1F6",
                                                    width: "40px",
                                                    height: "40px",
                                                    p: 1,
                                                    borderRadius: 0,
                                                  }}
                                                  onClick={() =>
                                                    handleIncrement(
                                                      category,
                                                      difficulty,
                                                      questionType
                                                    )
                                                  }
                                                >
                                                  <AddIcon />
                                                </IconButton>
                                              </Box>
                                            </Box>
                                          )
                                        )}
                                      </Box>
                                    </Box>
                                  )
                                )}
                              </CardContent>
                            </Card>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Box>
                  ))}
                </Grid>

                <Grid item xs={12}>
                  <Box
                    display="flex"
                    backgroundColor="#F1F5F6"
                    justifyContent="space-between"
                    position="fixed"
                    bottom={0}
                    right={0}
                    width="90%"
                    height="90px"
                    alignItems="center"
                    p={2}
                  >
                    <Box
                      sx={{
                        fontSize: "16px",
                        color: "#202124",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      Total Assessment Questions: {totalQuestions}
                    </Box>
                    <Box>
                      <Button
                        sx={{
                          backgroundColor: "#0181BB",
                          color: "white",
                          textTransform: "capitalize",
                          fontFamily: "Be Vietnam Pro",
                          zIndex: 10000,
                          "&:hover": {
                            backgroundColor: "#01568C",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                        onClick={handleGenerateAssignmentClick}
                      >
                        Generate Assessment
                      </Button>
                    </Box>
                    {loading && <Loader />}
                    <Toaster
                      open={snackbarOpen}
                      message={snackbarMessage}
                      severity={snackbarSeverity}
                      onClose={() => setSnackbarOpen(false)}
                    />
                    <Toaster
                      open={submitToastOpen}
                      message={submitMessage}
                      severity={snackbarSeverity}
                      onClose={() => setSubmitToastOpen(false)}
                    />
                    <SuccessModal
                      open={isSuccessModalOpen}
                      onClose={() => setIsSuccessModalOpen(false)}
                      message={successMessage}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateNewAssessment;
