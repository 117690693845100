import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import ErrorToast from "components/Toaster/ErrorToast";
import api from '../api'

const MenteeDashboardCard = () => {
  const [menteeData, setMenteeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.userProfile);
  const userRole = userProfile?.data?.role;
  const accessToken = localStorage.getItem("access_token");

  const handleModuleClick = (id) => {
    navigate(`/dashboard/workspace/${id}`);
  };

  const fetchMenteeData = async () => {
    try {
      const response = await api.get('dashboard/');
      setMenteeData(response?.data);
      localStorage.setItem("menteeData", JSON.stringify(response?.data));
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
       <ErrorToast message="Your session has expired. Please log in again."/>
      } else {
        <ErrorToast message={'Failed to fetch mentee data'}/>
      }
      setError("Failed to fetch mentee data");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userRole === "mentee") {
      fetchMenteeData();
    }
  }, [userRole, accessToken]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: 'Be Vietnam Pro'
        }}
      >
        <Typography>{error}</Typography>
      </Box>
    );
  }

  if (!menteeData || Object.keys(menteeData)?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
          fontFamily: "Be Vietnam Pro",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Be Vietnam Pro",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          No Data Available
        </Typography>
      </Box>
    );
  }

  const {
    id,
    name,
    description,
    is_completed,
    progress,
    curent_module,
    next_module,
  } = menteeData || {};
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Typography variant="h5" sx={{ 
        mb: 3, 
        fontFamily: 'Be Vietnam Pro',
        fontWeight: 600,
        color: '#202124'
      }}>
        My Enrolled Courses
      </Typography>
      <Card sx={{ 
        borderRadius: "10px", 
        border: "1px solid #E0E0E0",
        boxShadow: 'none',
        mb: 3 
      }}>
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ 
              color: "#595A5F", 
              fontSize: "14px",
              fontFamily: 'Be Vietnam Pro'
            }}>
              Started {new Date(menteeData?.timestamp).toLocaleDateString()}
            </Typography>
          </Box>

          <Typography sx={{
            color: "#202124",
            fontSize: "24px",
            fontWeight: 500,
            fontFamily: 'Be Vietnam Pro',
            mb: 2
          }}>
            {name}
          </Typography>

          <Typography sx={{ 
            color: "#595A5F", 
            fontSize: "14px", 
            mb: 3,
            fontFamily: 'Be Vietnam Pro'
          }}>
            {description}
          </Typography>

          <Box sx={{ mb: 3 }}>
            <Typography sx={{
              fontSize: "14px",
              color: "#595A5F",
              fontFamily: 'Be Vietnam Pro',
              mb: 1
            }}>
              Course Progress
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ 
                  flex: 1,
                  height: 8,
                  borderRadius: "10px",
                  backgroundColor: '#E0E0E0',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#1976D2'
                  }
                }}
              />
              <Typography sx={{ 
                color: "#595A5F",
                fontSize: "14px",
                fontFamily: 'Be Vietnam Pro'
              }}>
                {`${progress}% completed`}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography sx={{ 
              color: "#595A5F", 
              fontSize: "14px",
              fontFamily: 'Be Vietnam Pro',
              mb: 1
            }}>
              Current Module
            </Typography>
            <Box
              onClick={() => handleModuleClick(menteeData?.id)}
              sx={{
                backgroundColor: "#F5F5F5",
                borderRadius: "5px",
                p: 2,
                cursor: "pointer",
                '&:hover': {
                  backgroundColor: "#EEEEEE"
                }
              }}
            >
              <Typography sx={{
                color: "#202124",
                fontSize: "14px",
                fontFamily: 'Be Vietnam Pro'
              }}>
                Module {curent_module?.module}: {curent_module?.title}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography sx={{ 
              color: "#595A5F", 
              fontSize: "14px",
              fontFamily: 'Be Vietnam Pro',
              mb: 1
            }}>
              Coming Up Next
            </Typography>
            <Box
              onClick={() => handleModuleClick(menteeData?.id)}
              sx={{
                backgroundColor: "#F5F5F5",
                borderRadius: "5px",
                p: 2,
                cursor: "pointer",
                '&:hover': {
                  backgroundColor: "#EEEEEE"
                }
              }}
            >
              <Typography sx={{
                color: "#202124",
                fontSize: "14px",
                fontFamily: 'Be Vietnam Pro'
              }}>
                Module {next_module?.module}: {next_module?.title}
              </Typography>
            </Box>
          </Box>

          <Button
            variant="contained"
            onClick={() => handleModuleClick(menteeData?.id)}
            sx={{
              textTransform: 'none',
              fontFamily: 'Be Vietnam Pro',
              borderRadius: '5px',
              backgroundColor: '#1976D2',
              '&:hover': {
                backgroundColor: '#1565C0'
              }
            }}
          >
            Continue Learning
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default MenteeDashboardCard;
