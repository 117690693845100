// src/components/TestCaseModal.jsx
import React, { useState, useEffect } from 'react';
import './TestCaseModal.css'

const TestCaseModal = ({ isOpen, onClose, testCases }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);

  useEffect(() => {
    const keys = Object.keys(testCases);
    if (keys.length > 0) {
      setSelectedSubmissionId(keys[0]);
    }
  }, [testCases]);

  if (!isOpen) return null;

  const handleSubmissionChange = (event) => {
    setSelectedSubmissionId(event.target.value);
    setExpandedIndex(null); // Reset expanded index when changing submission
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const currentTestCases = testCases[selectedSubmissionId] || [];
  return (
    <div className="modal" style={{ zIndex: 1000 }}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <p>
          <strong>Submission ID:</strong>
          <div className="select-container">
            <select value={selectedSubmissionId} onChange={handleSubmissionChange}>
              {Object.keys(testCases).map((id) => (
                <option key={id} value={id}>{id}</option>
              ))}
            </select>
          </div>
        </p>
        {currentTestCases.map((testCase, index) => (
          <div key={index} className="test-case">
            <div className="test-case-header" onClick={() => toggleExpand(index)}>
              <h2 
                className='test-case-title' 
                style={{ color: testCase.is_correct ? 'green' : 'red' }}
              >
                {testCase.is_correct ? '✔' : '✖'} Test Case {index + 1}
              </h2>
              <span className="score">Score: {testCase?.score}</span>
              <span className="toggle-icon">{expandedIndex === index ? '▲' : '▼'}</span>
            </div>
            <div className={`test-case-details ${expandedIndex === index ? 'expanded' : ''}`}>
              <p><strong>Input:</strong> <span className="value">{testCase?.input}</span></p>
              <p><strong>Output:</strong> <span className="value">{testCase?.output}</span></p>
              <p><strong>Actual Output:</strong> <span className="value">{testCase?.actual_output}</span></p>
              <p><strong>Run Time:</strong> <span className="value">{testCase?.run_time}</span></p>
              {testCase?.error_message && <p><strong>Error Message:</strong> <span className="value">{testCase.error_message}</span></p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestCaseModal;