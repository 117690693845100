import React from "react";
import { Box, Button } from "@mui/material";
import "./Footer.css";

const Footer = ({ handleSubmit, handleBack }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      bottom={0}
      width="100%"
      bgcolor="white"
      p={2}
      boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
    >
      <Button
        variant="contained"
        color="secondary"
        onClick={handleBack}
        className="back-button"
      >
        Back to Question Types
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className="submit-button"
      >
        Submit
      </Button>
    </Box>
  );
};

export default Footer;
