import React, { useState, useEffect } from "react";
import chats_icon from "../../assets/chatImages/chats_icon.svg";
import { useUser } from "../context/UserContext";
import { getDb } from "../firebase/auth";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";

function ChatList({ setSelectedChat }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  const { role, mentorId, mentiiId, mentorData, mentiiData } = useUser();
  const [chatData, setChatData] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [lastMessages, setLastMessages] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let chatData = [];
      let firstId = "";

      if (role) {
        if (role === "mentor") {
          chatData = mentiiData;
          firstId = mentorId;
        } else {
          chatData = mentorData;
        }
      }

      setChatData(chatData);

      if (chatData.length === 0) {
        setLoading(false);
        return;
      }

      const db = await getDb();
      const lastMessagesData = {};

      chatData.forEach((chat) => {
        const chatId =
          role === "mentor" ? `${mentorId}${chat.id}` : `${chat.id}${mentiiId}`;
        const messagesQuery = query(
          collection(db, chatId),
          orderBy("timeStamp", "desc"),
          limit(1)
        );

        onSnapshot(messagesQuery, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const timeStamp = doc.data().timeStamp.toDate();
            const formattedDate = `${
              timeStamp.getMonth() + 1
            }/${timeStamp.getDate()}/${timeStamp.getFullYear()}`;
            lastMessagesData[chat.id] = formattedDate;
          });
          setLastMessages({ ...lastMessagesData });
        });
      });

      setLoading(false);
    };

    fetchData();
  }, [role, mentorData, mentiiData, mentorId, mentiiId]);

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    setSelectedChatId(chat.id);
  };

  // Handle clearing data on page unload if needed
  useEffect(() => {
    const handleUnload = () => {
      // Any clean-up logic if necessary
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return (
    <div className="chatlist">
      <div className="logos">
        {/* <div className="edgent" onClick={handleClick}>
          <img src={logo} alt="Edgent" />
        </div> */}
        <div className="frame">
          <img src={chats_icon} alt="Frame" />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        chatData.map((chat) => (
          <div
            key={chat.id}
            className={`chat-item ${
              chat.id === selectedChatId ? "selected" : ""
            }`}
            onClick={() => handleChatClick(chat)}
          >
            <div className="profile-pic">
              <div className="avatar-circle">
                {chat.name ? chat.name.charAt(0).toUpperCase() : '?'}
              </div>
            </div>
            <div className="chat-details">
              <div className="chat-name">{chat.name}</div>
              <div className="chat-message">{chat.location}</div>
            </div>
            <div className="chatlist-timestamp">
              {lastMessages[chat.id] || ""}
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default ChatList;
