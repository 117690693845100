import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [googleResponse, setGoogleResponse] = useState(null);

  useEffect(() => {
    const storedAccessToken = localStorage.getItem('access_token');
    const storedTokenExpiration = localStorage.getItem('token_expiration');
    const expirationTime = storedTokenExpiration ? parseInt(storedTokenExpiration) : null;


    // Check if the stored access token is not expired
    if (storedAccessToken && expirationTime && expirationTime > Date.now()) {
      setGoogleResponse({
        access_token: storedAccessToken,
        expires_in: Math.floor((expirationTime - Date.now()) / 1000),
      });
    }
  }, []);

  const updateGoogleResponse = (response) => {
    setGoogleResponse(response);
  
    const defaultExpirationTimeInSeconds = 12 * 3600; // 12 hour
    const expirationTime = Date.now() + (defaultExpirationTimeInSeconds) * 1000;
    localStorage.setItem('access_token', response.access_token);
    localStorage.setItem('token_expiration', expirationTime);
  };
  

  return (
    <AuthContext.Provider value={{ googleResponse, updateGoogleResponse }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
