import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Assignment, Person, TrendingUp, Public } from "@mui/icons-material";
import Lightbulb from '@mui/icons-material/Lightbulb';

const steps = [
  {
    number: 1,
    title: "Onboarding & Profile Evaluation",
    description:
      "Begin your journey with a comprehensive onboarding process and profile assessment.",
    details:
      "Your profile is evaluated to ensure alignment with our program requirements. This step ensures we match you with the right resources, mentors, and opportunities for maximum impact.",
    icon: <Assignment fontSize="large" sx={{ color: "#0181BB" }} />,
  },
  {
    number: 2,
    title: "Personalized Mentorship Planning",
    description:
      "Collaborate with a mentor to define your goals and create a tailored roadmap.",
    details:
      "Engage in a detailed one-on-one session with an experienced mentor to outline your objectives, design a personalized program roadmap, and establish regular mentorship sessions for consistent guidance.",
    icon: <Person fontSize="large" sx={{ color: "#0181BB" }} />,
  },
  {
    number: 3,
    title: "Skill Development & Assessment",
    description:
      "Leverage curated resources to enhance your skills and prepare for career success.",
    details:
      "Access structured course materials, complete hands-on assignments, and undertake regular assessments. These resources are designed to help you build technical expertise and prepare effectively for career advancement.",
    icon: <TrendingUp fontSize="large" sx={{ color: "#0181BB" }} />,
  },
  {
    number: 4,
    title: "Gain Practical Experience",
    description:
      "Work on multiple projects and internships with companies.",
    details:
      "Engage in real-world projects and internships to gain practical industry experience. Collaborate with companies to enhance your skills and build a strong portfolio that reflects your expertise.",
    icon: <Public fontSize="large" sx={{ color: "#0181BB" }} />,
  },
  {
    number: 5,
    title: "Career Readiness",
    description:
      "Mock interviews, profile building, networking for referrals, and cracking jobs.",
    details:
      "Prepare for the job market with mock interview sessions, resume and profile enhancement, and strategic networking opportunities. Receive guidance to secure referrals and successfully land your dream job.",
    icon: <Assignment fontSize="large" sx={{ color: "#0181BB" }} />,
  },
  {
    number: 6,
    title: "Startup/Research Opportunity (Optional)",
    description:
      "Explore opportunities to launch or collaborate on innovative startup ideas.",
    details:
      "Learn how to build a startup from scratch with guidance from industry experts. Collaborate with peers, access seed funding opportunities, and receive mentorship to turn your innovative ideas into reality.",
    icon: <Lightbulb fontSize="large" sx={{ color: "#0181BB" }} />,
  },
];



const HowItWorks = () => {
  const [expanded, setExpanded] = useState(null); // Track the expanded accordion

  const handleAccordionChange = (index) => {
    setExpanded((prev) => (prev === index ? null : index)); // Toggle accordion
  };

  return (
    <Box sx={{ width: "100%", my: 4, p: 2 }}>
      {/* Section Header */}
      <Typography
        variant="h4"
        sx={{
          color: "#0181BB",
          fontWeight: 600,
          fontFamily: "Be Vietnam Pro",
          mb: 2,
          textAlign: "center", // Center align header
          fontSize: { xs: "32px", sm: "36px", md: "52px" },
        }}
      >
        How it works
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#595A5F",
          fontFamily: "Be Vietnam Pro",
          mb: 4,
          textAlign: "center", // Center align sub-header
          fontSize: { xs: "16px", sm: "18px", md: "20px" },
        }}
      >
        Your journey to success starts here. Follow these simple steps to
        transform your career with Edgent.
      </Typography>

      {/* Steps */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mx: "auto",
          maxWidth: "800px",
        }}
      >
        {steps.map((step, index) => (
          <Accordion
            key={index}
            expanded={expanded === index} // Only expand the current accordion
            onChange={() => handleAccordionChange(index)}
            sx={{
              borderRadius: 2,
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              border: "1px solid #E0F5FF",
              overflow: "hidden",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#0181BB" }} />}
              sx={{
                display: "flex",
                alignItems: "center",
                p: 2,
              }}
            >
              {/* Icon */}
              <Card
                sx={{
                  backgroundColor: "#E0F5FF",
                  borderRadius: "50%",
                  width: 60,
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: 2,
                }}
              >
                {step.icon}
              </Card>
              {/* Content */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Be Vietnam Pro",
                    color: "#0181BB",
                    fontSize: { xs: "16px", sm: "18px", md: "20px" },
                  }}
                >
                  Step {step.number}: {step.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#595A5F",
                    fontFamily: "Be Vietnam Pro",
                    fontSize: { xs: "16px", sm: "18px", md: "18px" },
                  }}
                >
                  {step.description}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 2,
                textAlign: "left", // Align accordion text to the left
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "#595A5F",
                  fontFamily: "Be Vietnam Pro",
                  fontSize: { xs: "16px", sm: "18px", md: "18px" },
                }}
              >
                {step.details}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default HowItWorks;
