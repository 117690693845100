import React, { useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Box,
  Container,
  Divider,
  Typography,
  ListItemIcon,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import dashboardIcon from "../../assets/svgImages/dashboardIcon.svg";
import edgentLogo from "../../assets/svgImages/edgentLogo.svg";
import Loader from "../../components/Loader";
import edgent from "../../assets/homepage/edgentWhite.svg";

const NavbarLoggedInNew = ({ hideMenu }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Drawer state
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setLoading(true);
    localStorage.setItem("access_token", "");
    localStorage.setItem("token_expiration", "0");
    handleClose();
    setTimeout(() => {
      setLoading(false);
      navigate("/");
    }, 1000);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        backgroundColor: "#0181BB",
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
        color: "#fff",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {/* Logo Section */}
      <Box sx={{ padding: "16px 20px", marginBottom: "20px" }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <img
            src={edgent}
            alt="Logo"
            style={{ width: "32px", height: "32px" }}
          />
          <Typography sx={{ 
            color: 'white', 
            fontSize: '20px',
            fontFamily: "Be Vietnam Pro"
          }}>
            Edgent
          </Typography>
        </Box>
      </Box>

      {/* Navigation Links */}
      <List sx={{ padding: '0 8px' }}>
        <ListItem 
          button 
          onClick={() => navigate("/dashboard")}
          sx={{
            borderRadius: '8px',
            mb: 1,
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
          }}
        >
          <img
            src={dashboardIcon}
            alt="Dashboard"
            style={{ marginRight: "12px", width: "20px" }}
          />
          <ListItemText
            primary="Dashboard"
            sx={{
              '& .MuiTypography-root': { 
                fontFamily: "Be Vietnam Pro",
                fontWeight: 500
              }
            }}
          />
        </ListItem>

        <ListItem 
          button 
          onClick={() => navigate("/chat")}
          sx={{
            borderRadius: '8px',
            mb: 1,
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
          }}
        >
          <ChatBubbleOutlineOutlinedIcon
            sx={{ color: "white", marginRight: "12px" }}
          />
          <ListItemText
            primary="Chat"
            sx={{
              '& .MuiTypography-root': { 
                fontFamily: "Be Vietnam Pro",
                fontWeight: 500
              }
            }}
          />
        </ListItem>

        <ListItem 
          button 
          onClick={() => navigate("/profile")}
          sx={{
            borderRadius: '8px',
            mb: 1,
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
          }}
        >
          <AccountCircleOutlinedIcon
            sx={{ color: "white", marginRight: "12px" }}
          />
          <ListItemText
            primary="Profile"
            sx={{
              '& .MuiTypography-root': { 
                fontFamily: "Be Vietnam Pro",
                fontWeight: 500
              }
            }}
          />
        </ListItem>
      </List>

      {/* Version Info - Bottom */}
      <Box sx={{ 
        marginTop: 'auto', 
        padding: '16px 20px',
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5
      }}>
        <Typography sx={{ 
          color: 'rgba(255,255,255,0.7)', 
          fontSize: '14px',
          fontFamily: "Be Vietnam Pro"
        }}>
          Edgent AI
        </Typography>
        <Typography sx={{ 
          color: 'rgba(255,255,255,0.7)', 
          fontSize: '14px',
          fontFamily: "Be Vietnam Pro"
        }}>
          v1.0.0
        </Typography>
      </Box>
    </Box>
  );

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#fff",
        boxShadow: "0px 3px 3px 0px #0000001A",
        top: 0,
        zIndex: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" , px: 2}}>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between", // Ensures left and right alignment
            alignItems: "center",
            minHeight: "64px !important",
            width: "100%",
          }}
        >
          {/* Left Section: Drawer Menu Icon and Logo */}
          <Box sx={{ 
            display: "flex", 
            alignItems: "center",
            ml: 1,
            gap: 1  
          }}>
            {/* Conditionally render MenuIcon based on hideMenu prop */}
            {!hideMenu && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{
                  color: "rgb(134, 134, 134)",
                }}
              >
                <MenuIcon />
              </IconButton>
            )}

            {/* Drawer Implementation */}
            <Drawer
              anchor="left"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              BackdropProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0)" }, // Transparent backdrop
              }}
            >
              {drawerContent}
            </Drawer>

            {/* Logo */}
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={edgentLogo}
                  alt="Logo"
                  style={{ width: "90px", height: "45px" }}
                />
              </Box>
            </Link>
          </Box>

          {/* Right Section: Account Icon */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              sx={{
                color: "#868686",
              }}
              onClick={handleClick}
            >
              <AccountCircleOutlinedIcon sx={{ fontSize: 28 }} />
            </IconButton>

            {/* Account Menu */}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  width: '200px',
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Typography
                sx={{
                  px: 2,
                  py: 1,
                  fontWeight: 500,
                  fontFamily: "Be Vietnam Pro",
                  color: '#1a1a1a'
                }}
              >
                My Account
              </Typography>
              <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/profile"
                sx={{ 
                  px: 2,
                  py: 1,
                  fontFamily: "Be Vietnam Pro",
                  '&:hover': {
                    backgroundColor: '#f5f5f5'
                  }
                }}
              >
                <AccountCircleOutlinedIcon sx={{ mr: 1, fontSize: 20, color: '#666' }} />
                <Typography sx={{ fontFamily: "Be Vietnam Pro", color: '#1a1a1a' }}>
                  Profile
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={handleLogout}
                sx={{ 
                  px: 2,
                  py: 1,
                  fontFamily: "Be Vietnam Pro",
                  '&:hover': {
                    backgroundColor: '#f5f5f5'
                  }
                }}
              >
                <Logout sx={{ mr: 1, fontSize: 20, color: '#666' }} />
                <Typography sx={{ fontFamily: "Be Vietnam Pro", color: '#ff3b30' }}>
                  Log out
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Box>
      {loading && <Loader />}
    </AppBar>
  );
};

export default NavbarLoggedInNew;