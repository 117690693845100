import React from "react";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"; // Dedicated Mentor
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline"; // Chat with Mentor
import SchoolIcon from "@mui/icons-material/School"; // Personalized Learning
import BookIcon from "@mui/icons-material/Book"; // Proprietary Content & Exams
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"; // Free Mock Interviews
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch"; // Startup Accelerator
import WorkOutlineIcon from "@mui/icons-material/WorkOutline"; // Add this line

const offerings = [
  {
    title: "Dedicated Mentor",
    description:
      "A dedicated mentor with 30-50 hours of focused 1:1 learning sessions flexibly designed as per your schedule",
    icon: <PersonOutlineIcon fontSize="large" sx={{ color: "#fff" }} />,
  },
  {
    title: "Chat with Mentor anytime",
    description: "A 24x7 chat window to connect with your mentor anytime",
    icon: <ChatBubbleOutlineIcon fontSize="large" sx={{ color: "#fff" }} />,
  },
  {
    title: "Personalized Learning",
    description:
      "A flexible learning roadmap curated in line with your specific career aspirations",
    icon: <SchoolIcon fontSize="large" sx={{ color: "#fff" }} />,
  },
  {
    title: "Proprietary Content & Exams",
    description:
      "Free proprietary subject content, quizzes, and assignments, all aligning with your career path",
    icon: <BookIcon fontSize="large" sx={{ color: "#fff" }} />,
  },
  {
    title: "Project and Internship Opportunities",
    description:
      "Access to exclusive project and internship opportunities to build hands-on experience",
    icon: <WorkOutlineIcon fontSize="large" sx={{ color: "#fff" }} />,
  },
  {
    title: "Mock Interviews & Job Referrals",
    description:
      "Free mock interviews and CV reviews by top HR professionals, plus job portal referrals to boost your job search.",
    icon: <QuestionAnswerIcon fontSize="large" sx={{ color: "#fff" }} />,
  },
  {
    title: "Edgent Projects/ Research",
    description:"Collaborate with Edgent for mentorship, resources, and funding to bring your ideas to life.",
    icon: <RocketLaunchIcon fontSize="large" sx={{ color: "#fff" }} />,
  },
];

const EdgentOfferings = () => {
  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "center",
        py: 6,
        px: { xs: 2, sm: 4, md: 8 },
        backgroundColor: "#EAF2FB",
        fontFamily: "Be Vietnam Pro",
      }}
    >
      {/* Section Heading */}
      <Typography
        variant="h4"
        sx={{
          color: "#0181BB",
          fontWeight: 600,
          mb: 1,
          fontSize: { xs: "32px", sm: "36px", md: "52px" },
          fontFamily: "Be Vietnam Pro",
        }}
      >
        Edgent Offerings
      </Typography>
      <Typography
        sx={{
          color: "#606060",
          fontSize: { xs: "14px", md: "16px" },
          mb: 4,
          fontFamily: "Be Vietnam Pro",
        }}
      >
        Comprehensive support and resources to accelerate your career growth
      </Typography>

      {/* Grid Layout */}
      <Grid container spacing={3} justifyContent="center">
        {offerings.map((offering, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                display: "flex",
                alignItems: "flex-start",
                width: "100%",
                height: "220px",
                borderRadius: "16px",
                boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
                background: "#fff",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.02)",
                },
                padding: "16px",
                marginBottom: 0,
              }}
            >
              {/* Icon Section */}
              <Box
                sx={{
                  minWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  background: "#0181BB",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "16px",
                  flexShrink: 0,
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                >
                  {offering.icon}
                </Box>
              </Box>

              {/* Content Section */}
              <Box sx={{ flexGrow: 1 }}>
                <CardContent
                  sx={{
                    textAlign: "left",
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    // Remove minHeight or set it to auto
                    minHeight: "auto", // Adjusted to auto
                    marginBottom: 0, // No extra bottom margin
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: "16px", md: "20px" },
                      color: "#202124",
                      mb: 0.5,
                      fontFamily: "Be Vietnam Pro",
                    }}
                  >
                    {offering.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "18px" },
                      color: "#595A5F",
                      lineHeight: 1.5,
                      fontFamily: "Be Vietnam Pro",
                    }}
                  >
                    {offering.description}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EdgentOfferings;
