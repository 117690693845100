import React from "react";
import { Card } from "react-bootstrap";
import "./component.scss";
import { CardContent, Typography, Grid } from "@mui/material";

function CallScheduled() {
  return (
    <div className="w-100 text-center m-4">
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={8} md={6} lg={4} m={2}>
          <Card className="mb-3 elegant-card">
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Be Vietnam Pro",
                  color: 'rgba(0,0,0,0.7)'
                }}
              >
                Schedule your call
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Be Vietnam Pro",
                  color: 'rgba(0,0,0,0.7)'
                }}
              >
                Your call is scheduled Successfully. Please check your
                registered mail for the meet details.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default CallScheduled;
