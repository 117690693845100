import React from "react";
import dp from "../../assets/chatImages/dp.svg";
import { IoArrowBackOutline } from "react-icons/io5";

function Top({ chat, handleBackClick }) {
  return (
    <div className="title-bar">
      <div className="chat-item2">
        <button className="back-button" onClick={handleBackClick}>
         <IoArrowBackOutline />
        </button>
        <div className="profile-pic2">
          <div className="initial-avatar">
            {chat.name ? chat.name.charAt(0).toUpperCase() : '?'}
          </div>
        </div>
        <div className="chat-details2">
          <div className="chat-name2">{chat.name}</div>
          <div className="chat-message2">{chat.location}</div>
        </div>
      </div>
    </div>
  );
}

export default Top;
