import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SessionIcon from "../assets/svgImages/sessionIcon.svg";
import VideoIcon from "../assets/svgImages/videoIcon.svg";
import TasklistIcon from "../assets/svgImages/taskListIcon.svg";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReadingMaterial from "../assets/svgImages/readingMaterialIcon.svg";
import CustomDrawer from "./CustomDrawer";
import Assessment from "./Assessment";
import Assignment from "./Assignment";
import Readingmaterial from "./ReadingMaterial";
import dayjs from "dayjs";
import ScheduleMeetingModal from "./ScheduleMeetingModal";
import { useParams } from "react-router-dom";
import AssessmentMentee from "./AssessmentMentee";
import { useSelector } from "react-redux";
import AssignmentMentee from "./AssignmentMentee";
import ErrorToast from "components/Toaster/ErrorToast";
import { format } from "date-fns";
function ConceptBuildingData({ course }) {
  const [expanded, setIsExpanded] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAssignmentDrawerOpen, setIsAssignmentDrawerOpen] = useState(false);
  const [isReadingMaterialDrawerOpen, setIsReadingMaterialDrawerOpen] =
    useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [menteeData, setMenteeData] = useState(null);
  const { id } = useParams(); // Extract the ID from the URL
  const userProfile = useSelector((state) => state.userProfile);
  const userRole = userProfile?.data?.role;
  const { concept_meeting_data } = course || {};
  const { meeting_link, start_time, end_time } = concept_meeting_data || {};

  // Format the start_time to a readable string
  const formattedTime =
    start_time && end_time
      ? format(new Date(start_time), "EEEE, dd MMM h:mm a") +
        " - " +
        format(new Date(end_time), "h:mm a")
      : "";
  const isMeetingScheduled = Boolean(meeting_link);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleAssignmentDrawer = () => {
    setIsAssignmentDrawerOpen(!isDrawerOpen);
  };

  const toggleReadingMaterialDrawer = () => {
    setIsReadingMaterialDrawerOpen(!isReadingMaterialDrawerOpen);
  };

  const toggleAccordion = () => {
    setIsExpanded(!expanded);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function generateTimeSlots() {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        timeSlots.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return timeSlots;
  }

  useEffect(() => {
    const storedMenteeData = localStorage.getItem("menteeData");
    if (storedMenteeData) {
      try {
        const parsedData = JSON.parse(storedMenteeData);
        let matchedMentee = null;

        if (Array.isArray(parsedData)) {
          matchedMentee = parsedData.find(
            (mentee) => mentee.id.toString() === id
          );
        } else if (typeof parsedData === "object" && parsedData !== null) {
          if (parsedData.id.toString() === id) {
            matchedMentee = parsedData;
          }
        }

        if (matchedMentee) {
          setMenteeData(matchedMentee);
        } else {
          <ErrorToast message="No matching mentee found for the given ID." />;
        }
      } catch (error) {
        <ErrorToast message={error} />;
      }
    } else {
      <ErrorToast message="No mentee data found." />;
    }
  }, [id]);

  const timeSlots = generateTimeSlots();

  // const fetchData = async () => {
  //   const formattedDate = dayjs(selectedDate).format("DD-MM-YYYY");

  //   const body = {
  //     "selected-date": formattedDate,
  //     "selected-time": startTime,
  //   };

  //   try {
  //     const response = await axios.post(
  //       `${baseURL}workspace/schedule-meeting/`,
  //       body,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     setResponseData(response.data);
  //   } catch (error) {
  //     console.error("Error posting data:", error);
  //   }
  // };

  // const handleSave = () => {
  //   fetchData();
  // };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            backgroundColor="#E5F1F6"
            p={1}
            mt={2}
          >
            <Box display="flex" alignItems="center">
              <img src={SessionIcon} alt="Session" style={{ marginRight: 8 }} />

              {isMeetingScheduled ? (
                <Tooltip title={formattedTime} arrow>
                  <Typography
                    sx={{
                      color: "#202124",
                      fontSize: "14px",
                      fontFamily: "Be Vietnam Pro",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(meeting_link, "_blank")}
                  >
                    Schedule 1:1
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  sx={{
                    color: "#202124",
                    fontSize: "14px",
                    fontFamily: "Be Vietnam Pro",
                    cursor: "pointer",
                  }}
                  onClick={handleModalOpen} // Open the modal when the meeting is not scheduled
                >
                  Schedule 1:1
                </Typography>
              )}

              {isMeetingScheduled && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: "12px",
                    textTransform: "none",
                    borderRadius: "6px",
                    ml: 2,
                    height: "24px",
                    mt: "2px",
                    backgroundColor: "#0172A6",
                    "&:hover": {
                      backgroundColor: "#015d8d",
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "#014d74",
                    },
                  }}
                  onClick={() => window.open(meeting_link, "_blank")}
                >
                  Join Now
                </Button>
              )}
            </Box>

            {isMeetingScheduled && (
              <Tooltip title="Reschedule Meeting" arrow>
                <ModeEditIcon
                  sx={{ fontSize: "18px", cursor: "pointer" }}
                  onClick={handleModalOpen} // Open the modal for rescheduling
                />
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: "#E5F1F6",
              width: "100%",
              display: "flex",
              p: 1,
            }}
          >
            <img src={VideoIcon} alt="Video" style={{ marginRight: 8 }} />
            <Typography
              sx={{
                color: "#202124",
                fontSize: "14px",
                fontFamily: "Be Vietnam Pro",
                cursor: "pointer",
              }}
            >
              Session Tracking
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: "#E5F1F6",
              width: "100%",
              display: "flex",
              p: 1,
              cursor: "pointer",
            }}
            onClick={() => toggleReadingMaterialDrawer()}
          >
            <img src={ReadingMaterial} alt="Video" style={{ marginRight: 8 }} />
            <Typography
              sx={{
                color: "#202124",
                fontSize: "14px",
                fontFamily: "Be Vietnam Pro",
                cursor: "pointer",
              }}
            >
              Reading material / Edgent Content
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded}
            onChange={() => toggleAccordion()}
            sx={{
              ".MuiAccordionSummary-content": {
                margin: "0px",
              },
              padding: "8px",
              borderRadius: "10px",
              boxShadow: "none",
              padding: "0px",
            }}
            p={1}
          >
            <AccordionSummary
              sx={{
                backgroundColor: "#E5F1F6",
                width: "100%",
                display: "flex",
                alignItems: "center",
                py: 0,
                px: 1,
                minHeight: "37px !important",
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <img
                src={TasklistIcon}
                alt="Task List"
                style={{ marginRight: 8 }}
              />
              <Typography
                sx={{
                  color: "#202124",
                  fontSize: "14px",
                  flex: 1,
                  fontFamily: "Be Vietnam Pro",
                  cursor: "pointer",
                }}
              >
                Task Lists
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  backgroundColor: "#E8EDF0",
                  ml: 4,
                  p: 1,
                  width: "98%",
                  my: 1,
                  fontSize: "15px",
                  fontFamily: "Be Vietnam Pro",
                  cursor: "pointer",
                }}
                onClick={() => toggleAssignmentDrawer()}
              >
                Assignment
              </Box>
              <Box
                sx={{
                  backgroundColor: "#E8EDF0",
                  ml: 4,
                  p: 1,
                  width: "98%",
                  fontSize: "15px",
                  fontFamily: "Be Vietnam Pro",
                  cursor: "pointer",
                }}
                onClick={() => toggleDrawer()}
              >
                Assessment
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: "#E5F1F6",
              width: "100%",
              display: "flex",
              p: 1,
            }}
          >
            <img src={FolderIcon} alt="Folder" style={{ marginRight: 8 }} />
            <Typography sx={{ color: "#202124", fontSize: "14px" }}>
              Mentor's repository for mentee
            </Typography>
          </Box>
        </Grid> */}
      </Grid>
      <CustomDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        title={"Assessment"}
        isBack={userRole === "mentee"}
      >
        <Typography variant="h6" marginTop={"60px"}>
          {userRole === "mentor" ? (
            <Assessment
              course={course}
              onClose={() => setIsDrawerOpen(false)}
            />
          ) : userRole === "mentee" ? (
            <AssessmentMentee />
          ) : (
            <Typography
              fontSize={"16px"}
              fontWeight={500}
              variant="h6"
              fontFamily={"Be Vietnam Pro"}
            >
              No Assessment available.
            </Typography>
          )}
        </Typography>
      </CustomDrawer>
      <CustomDrawer
        open={isAssignmentDrawerOpen}
        onClose={() => setIsAssignmentDrawerOpen(false)}
        title={"Assignment"}
      >
        <Typography variant="h6" marginTop={"60px"}>
          {userRole === "mentor" ? <Assignment /> : <AssignmentMentee />}
        </Typography>
      </CustomDrawer>
      <CustomDrawer
        open={isReadingMaterialDrawerOpen}
        onClose={() => setIsReadingMaterialDrawerOpen(false)}
        title={"Reading Material"}
      >
        <Typography variant="h6" marginTop={"60px"}>
          <Readingmaterial course={course} />
        </Typography>
      </CustomDrawer>
      <ScheduleMeetingModal
        open={openModal}
        onClose={handleModalClose}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
        timeSlots={timeSlots}
        startTime={startTime}
        handleStartTimeChange={handleStartTimeChange}
        // handleSave={handleSave}
        menteeData={menteeData}
        type={1}
      />
      {/* <Grid container>
        <Grid item xs={12} sm={12}>
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            sx={{
              ".MuiPaper-root": {
                width: "90% !important",
              },
            }}
          >
            <Box p={2}>
              <Box display="flex" justifyContent="flex-end">
                <Box width={"100%"} display={'flex'} position={'absolute'} top={0} backgroundColor="#0181BB" right={0}>
                  <Box width={"100%"} height={'64px'}></Box>
                  <IconButton onClick={() => setIsDrawerOpen(false)}>
                    <CloseIcon sx={{color: 'white'}}/>
                  </IconButton>
                </Box>
              </Box>
              <Typography variant="h6">This is the Drawer content</Typography>
            </Box>
          </Drawer>
        </Grid>
      </Grid> */}
    </>
  );
}

export default ConceptBuildingData;
