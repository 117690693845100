import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Button,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import { baseURL } from "../aws-config";
import axios from "axios";
import Confetti from "react-confetti";
import Footer from "components/Footer/Footer";
import { questionTypeMap } from "../constant/index";
import Loader from "components/Loader";
import { useSelector } from "react-redux";
import ErrorToast from "components/Toaster/ErrorToast";
import api from "../api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AssessmentMentee = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [problemTypes, setProblemTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const storedAccessToken = localStorage.getItem("access_token");
  const selectedModuleId = useSelector(
    (state) => state.module.selectedModuleId
  );

  useEffect(() => {
    const fetchProblemTypes = async () => {
      try {
        const response = await api.get(
          `course/available-problem-types/${selectedModuleId}/`
        );
        setProblemTypes(response?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProblemTypes();
  }, [storedAccessToken]);


  const renderComponent = () => {
    if (!selectedComponent) return null;

    const SelectedComponent = questionTypeMap[selectedComponent].component;
    return <SelectedComponent />;
  };

  const handleBack = () => {
    setSelectedComponent(null);
  };

  const handleEndAssessment = () => {
    setConfetti(true);
    setOpen(true);
    setTimeout(() => {
      setConfetti(false);
    }, 3000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error.message}
      </Typography>
    );
  }

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      {confetti && <Confetti />}
      {selectedComponent ? (
        <>
          <Button
            onClick={() => {
              setSelectedComponent(null);
            }}
            sx={{
              marginBottom: 2,
              padding: 1,
              minWidth: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: 'absolute',
              top: '14px',
              color: 'white',
              left: '0px'
            }}
          >
            <ArrowBackIcon />
          </Button>
          {renderComponent()}
          {/* <Footer handleSubmit={handleSubmit} handleBack={handleBack} /> */}
        </>
      ) : problemTypes?.length > 0 ? (
        <>
          <Typography variant="h6" sx={{ marginBottom: 3, textAlign: "left" }}>
            Select Assessment Type
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {problemTypes?.map((type) => (
              <Grid item xs={12} sm={6} md={4} key={type}>
                <Card sx={{ mb: 2, boxShadow: 3, borderRadius: 3 }}>
                  <CardActionArea onClick={() => setSelectedComponent(type)}>
                    <CardContent>
                      <Typography variant="h6">
                        {questionTypeMap[type]?.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ position: "fixed", bottom: 30, right: 50 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEndAssessment}
            >
              End Assessment
            </Button>
          </Box>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  border: "1px solid #ccc",
                  boxShadow: 24,
                  p: 4,
                  textAlign: "center",
                  borderRadius: 2,
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold", color: "#4caf50" }}
                >
                  Assessment Successfully Submitted!
                </Typography>
                <Typography
                  id="transition-modal-description"
                  sx={{ mt: 2, color: "#757575" }}
                >
                  Your assessment has been successfully submitted. Thank you!
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                >
                  Close
                </Button>
              </Box>
            </Fade>
          </Modal>
        </>
      ) : (
        <Typography variant="h6" sx={{ marginBottom: 3, textAlign: "center" }}>
          No assessment is assigned
        </Typography>
      )}
    </Box>
  );
};

export default AssessmentMentee;
