import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Loader from "components/Loader";
import ErrorToast from "components/Toaster/ErrorToast";
import api from '../api'
import { useSelector } from "react-redux";
import SuccessToast from "components/Toaster/SuccesToast";
import "./meeting-modal.css";

const ScheduleMeetingModal = ({
  open,
  onClose,
  selectedDate,
  handleDateChange,
  timeSlots,
  startTime,
  handleStartTimeChange,
  menteeData,
  type
}) => {
  const selectedModuleId = useSelector(
    (state) => state.module.selectedModuleId
  );
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const accessToken = localStorage.getItem("access_token");
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  
  const fetchData = async () => {
    setIsLoading(true);
    const formattedDate = selectedDate?.format("DD-MM-YYYY");
  
    if (!formattedDate || !startTime) {
      setErrorMessage('Something went wrong');
      setShowError(true);
      setIsLoading(false);
      return;
    }
  
    const body = {
      "selected-date": formattedDate,
      "selected-time": startTime,
      "type": type,
    };
  
    try {
      const response = await api.post(
        `course/schedule-meeting/${selectedModuleId}/`,
        body,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setResponseData(response?.data);
      setShowSuccessToast(true); // Assuming you have a similar success toast state
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Something went wrong';
      setErrorMessage(errorMessage);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSave = () => {
    fetchData();
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A"; // Return "N/A" if the timestamp is not provided
    const date = new Date(timestamp);
    if (isNaN(date)) return "Invalid Date"; // Check if the date is valid
    return date.toISOString().split("T")[0]; // Extract YYYY-MM-DD from ISO string
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: 700,
          height: 600,
          borderRadius: "12px",
        }}
      >
        {isLoading ? (
          <Loader />
        ) : responseData ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent={"center"}
            height={"100%"}
          >
            <Typography variant="h5" gutterBottom>
              Your meeting is scheduled!
            </Typography>
            <Typography variant="body1" align="center">
              Please accept calendar invite in your email.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={onClose}
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          </Box>
        ) : (
          <>
            <Box display={"flex"} width={"100%"}>
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"space-between"}
              >
                <Box display={"flex"}>
                  <Typography
                    width={"118px"}
                    fontSize={"16px"}
                    fontWeight={600}
                  >
                    Schedule your call
                  </Typography>
                  <Box display={"flex"}>
                    {/* <Avatar
                      
                    >
                      {menteeData?.avatar ? (
                        <img
                          src={menteeData.avatar}
                          alt="avatar"
                          style={{ width: "100%", height: "100%" }}
                        />
                      ) : (
                        menteeData?.name?.charAt(0)?.toUpperCase() ||
                        "N/A"
                      )}
                    </Avatar> */}
                    <Box display={"flex"} flexDirection={"column"} ml={1}>
                      <Typography sx={{ color: "#0172A6", fontSize: "16px" }}>
                        {menteeData?.name || "N/A"}
                      </Typography>
                      <Typography sx={{ color: "#595A5F", fontSize: "14px" }}>
                        {menteeData?.state || "N/A"},{" "}
                        {menteeData?.country || "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#595A5F",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Course Started {formatDate(menteeData?.timestamp)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#202124",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {menteeData?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              mt={2}
              display={"flex"}
              justifyContent={"space-evenly"}
              height={"450px"}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  label="Pick a date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  disablePast
                />
              </LocalizationProvider>
              <Box mt={2}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccessTimeOutlinedIcon sx={{ marginRight: 1 }} />
                  Start Time
                </Typography>
                <TableContainer
                  component={Paper}
                  style={{
                    maxHeight: 400,
                    overflowY: "auto",
                    boxShadow: "none",
                  }}
                >
                  <Table>
                    <TableBody>
                      {timeSlots?.map((time, index) => (
                        <TableRow
                          key={index}
                          onClick={() => handleStartTimeChange(time)}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            sx={{
                              backgroundColor:
                                startTime === time ? "#0181BB" : "inherit",
                              color: startTime === time ? "white" : "inherit",
                              padding: "8px",
                            }}
                          >
                            {time}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"end"} mt={1}>
              <Button
                sx={{
                  color: "#202124",
                  fontSize: "14px", 
                  minHeight: "auto",
                }}
                size="small"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0181BB",
                  fontSize: "14px",
                  borderRadius: "6px",
                  color: "white",
                  ml: 2,
                  minHeight: "auto",
                  "&:hover": {
                    backgroundColor: "#0172A6",
                  },
                }}
                size="small"
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
            {showError && <ErrorToast message={errorMessage} />}
            {showSuccessToast && <SuccessToast message='Successfully scheduled the meeting.' />}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ScheduleMeetingModal;
