import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Tabs, Tab, Typography } from "@mui/material";
import ErrorToast from "../components/Toaster/ErrorToast"; // Assuming you have this component
import CreateNewAssessment from "./CreateNewAssessment";
import PreviousAssessment from "./ViewPreviousAssessment";
import api from "../api";

const Assessment = ({ course, onClose }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [fetchingAssessmentStatus, setFetchingAssessmentStatus] = useState(true);
  const [assessmentStatus, setAssessmentStatus] = useState(null);
  const [error, setError] = useState(null);
  const selectedModuleId = useSelector((state) => state.module.selectedModuleId);
  const pollingIntervalRef = useRef(null);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fetchAssessmentStatus = async () => {
    setFetchingAssessmentStatus(true);
    try {
      const response = await api.get(`course/${selectedModuleId}/assessment-status/`);
      const status = response?.data?.status;
      setAssessmentStatus(status);

      // If the status is 'IN_PROGRESS', start polling every 10 seconds
      if (status === "IN_PROGRESS") {
        pollingIntervalRef.current = setInterval(async () => {
          try {
            const pollingResponse = await api.get(`course/${selectedModuleId}/assessment-status/`);
            const pollingStatus = pollingResponse?.data?.status;
            setAssessmentStatus(pollingStatus);

            // Stop polling if status is no longer 'IN_PROGRESS'
            if (pollingStatus !== "IN_PROGRESS") {
              clearInterval(pollingIntervalRef.current);
              pollingIntervalRef.current = null;
            }
          } catch (pollingError) {
            clearInterval(pollingIntervalRef.current); // Stop polling on error
            pollingIntervalRef.current = null;
            setError(`Polling error: ${pollingError.message}`);
          }
        }, 10000); // Poll every 10 seconds
      }
    } catch (error) {
      setError(`Failed to fetch assessment status: ${error.message}`);
    } finally {
      setFetchingAssessmentStatus(false);
    }
  };

  useEffect(() => {
    fetchAssessmentStatus();

    // Cleanup function to clear the interval
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [selectedModuleId]);

  if (fetchingAssessmentStatus) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (assessmentStatus === "IN_PROGRESS") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
        <Typography sx={{ mt: 2, fontFamily: "Be Vietnam Pro", fontSize: "18px" }}>
          The assessment is being generated. Please wait...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label="Create New Assessment"
            sx={{ textTransform: "capitalize", fontFamily: "Be Vietnam Pro" }}
          />
          <Tab
            label="View Previous Assessment"
            sx={{ textTransform: "capitalize", fontFamily: "Be Vietnam Pro" }}
          />
        </Tabs>
      </Box>
      <Box>
        {selectedTab === 0 && (
          <CreateNewAssessment
            course={course}
            onClose={() => {
              onClose?.();
              fetchAssessmentStatus();
            }}
          />
        )}
        {selectedTab === 1 && <PreviousAssessment/>}
      </Box>
      {error && <ErrorToast message={error} />}
    </Box>
  );
};

export default Assessment;
