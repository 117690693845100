import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

const PreferenceNav = ({ setLanguage, initialLanguage, theme, setTheme }) => {
  return (
    <div className="preference-controls">
      <div className="language-selector">
        <label className="language-label">Language:</label>
        <select 
          value={initialLanguage} 
          onChange={(e) => setLanguage(e.target.value)}
          className="language-select"
        >
          <option value="python">Python</option>
          <option value="javascript">JavaScript</option>
          <option value="cpp">C++</option>
          <option value="java">Java</option>
          <option value="go">Go</option>
        </select>
      </div>
      
      <div className="language-selector">
        <label className="language-label">Theme:</label>
        <select 
          value={theme} 
          onChange={(e) => setTheme(e.target.value)}
          className="language-select"
        >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
        </select>
      </div>
    </div>
  );
};

export default PreferenceNav;
